<template>
  <div class="add-student-dialog-body">
    <StudentInfoForm
      :student-data="addForm"
      :submit-btn-text="submitBtnText"
      @save="saveHandler"
    />
  </div>
</template>

<script>
import bus from "common/bus";
import { oneApi } from "config/api";
import StudentInfoForm from "components/myAccount/StudentInfoForm/index.vue";

export default {
  components: { StudentInfoForm },
  props: {
    studentInfo: {
      type: Array,
    },
    isAppli: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      submitBtnText: this.$t("login.switchStudent.addStudent"),
      addForm: {
        nickName: "",
        firstName: "",
        lastName: "",
        gradeId: "",
        email: "",
      },
    };
  },
  computed: {
    masterUserInfo() {
      return this.$store.state.ucenter.masterUserData;
    },
  },
  async mounted() {
    this.resetData();
    if (this.studentInfo && this.studentInfo.length > 0 && !this.isAppli) {
      this.studentInfo.forEach((e) => {
        if (e.key == "givenName") {
          this.addForm.firstName = e.value;
        }
        if (e.key == "familyName") {
          this.addForm.lastName = e.value;
        }
        if (e.key == "gradeId") {
          this.addForm.gradeId = Number(e.gradeId)
          this.addForm.gradeName = e.value
        }
        if (e.key == "nickName") {
          this.addForm.nickName = e.value;
        }
        if (e.key == "email") {
          this.addForm.email = e.value;
        }
      });
    }
    await this.init();
    this.initForm();
  },
  methods: {
    async init() {
      this.isLoading = true;
      if (!this.masterUserInfo.email) {
        await this.getUserInfo();
      }
    },
    initForm() {
      const { email } = this.masterUserInfo;
      this.addForm.email = email;
    },
    resetData() {
      this.addForm.nickName = "";
      this.addForm.firstName = "";
      this.addForm.lastName = "";
      this.addForm.gradeId = "";
      this.addForm.email = "";
    },
    saveHandler(newData) {
      this.addForm = Object.assign(this.addForm, newData);
      this.confirmEdit();
    },
    async confirmEdit() {
      const params = {
        nickName: this.addForm.nickName,
        firstName: this.addForm.firstName,
        lastName: this.addForm.lastName,
        gradeId: this.addForm.gradeId,
        email: this.addForm.email,
        sourceCode: "sibling",
        mediaCode: "sibling",
      };
      const res = await this.$axios.post(oneApi.addNewStudent, params, {
        rewritePostBody: true,
      });

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg || "Failed to add student, please try again");
        return;
      }
      this.$Toast(this.$t('featureComponents.page.tips.addSuccess'))

      const resData = res.data || {};

      this.$emit("add-success", resData.id);
      bus.$emit("switchStudent.refresh", resData.id);
    },
    async getUserInfo() {
      const res = await this.$axios.post(
        oneApi.queryMasterUserInfo,
        {},
        { rewritePostBody: true }
      );

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
        this.initLoading = false;
        return;
      }
      this.$store.commit("ucenter/SET_MASTER_USER", res.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-student-dialog-body {
  padding: px2vw(40) 0 px2vw(48);
  height: 496px;
}
</style>
